/* Variables */
:root {
  /* Theme */
  --primary: rgb(213, 52, 124);
  --secondary: rgb(236, 162, 69);
  /* divs */
  --background: rgb(252, 252, 252);
  --container-background: rgb(255, 255, 255);
  --subcontainer-background: rgb(230, 230, 230);
  --gradient: linear-gradient(
    to bottom right,
    rgb(213, 52, 133),
    rgb(221, 161, 85)
  );
  --gradient-hover: linear-gradient(
    to bottom right,
    rgb(221, 161, 85),
    rgb(221, 161, 85)
  );
  --div-border-radius: 20px;
  --nav-item-border-radius: 10px;
  --popUp-background: rgba(255, 255, 255, 0.8);
  /* Fonts */
  --primary-font-color: rgb(148, 32, 84);
  --primary-font-color-lowOpacity: rgba(148, 32, 84, 0.5);
  --secondary-font-color: rgb(190, 130, 55);
  --bold: 600;
  --non-bold: 400;
  --errors: rgba(231, 16, 16);
  --errors-nonHover: rgba(231, 16, 16, 0.5);
  /* Extras */
  --separators-color: rgb(235, 174, 164);
  /* Shadows */
  --shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  --shadow-hover: 0px 7px 13px rgba(0, 0, 0, 0.16);
  /* Animations */
  --animation-short: all 0.1s ease-in-out;
  /* Scroll */
  --scroll: rgb(230, 230, 230);
  --scroll-hover: rgb(200, 200, 200);
  --scroll-background: var(--container-background);
  /* Blur */
  --blur: blur(10px) brightness(1);
  --blurColor: rgba(255, 255, 255, 0.5);
}

/* Global */
.app-dark {
  --primary-font-color: rgb(255, 231, 242);
  --primary-font-color-lowOpacity: rgba(255, 231, 242, 0.5);
  --container-background: rgb(15, 15, 15);
  --subcontainer-background: rgb(30, 30, 30);
  --background: rgb(0, 0, 0);
  --shadow: 0px 4px 10px rgb(0, 0, 0);
  --shadow-hover: 0px 4px 10px rgb(0, 0, 0);
  --separators-color: rgb(110, 82, 78);
  --scroll: rgb(25, 25, 25);
  --scroll-hover: rgb(30, 30, 30);
  --scroll-background: var(--container-background);
  --popUp-background: rgba(0, 0, 0, 0.8);
  --blur: blur(30px) brightness(0.3);
  --blurColor: rgba(40, 40, 40, 0.2);
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0;
}
.btn {
  background-image: var(--gradient);
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  font-weight: 600;
  cursor: pointer;
  transition: var(--animation-short);
  box-shadow: var(--shadow);
  text-align: center;
  border: 0;
}
.btn:hover {
  box-shadow: var(--shadow-hover);
  transform: translateY(-2px);
}
.app-container {
  display: flex;
  flex-direction: row;
}
::-webkit-scrollbar {
  width: 7px;
  position: absolute;
}
::-webkit-scrollbar-track {
  background: var(--scroll-background);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: var(--scroll);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-hover);
}
.tab-container {
  background-color: var(--background);
  color: var(--primary-font-color);
  display: flex;
  min-width: 82vw;
  max-width: 82vw;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}
.tab-container::-webkit-scrollbar {
  width: 0;
}
.content-container {
  background-color: var(--container-background);
  border-radius: var(--div-border-radius);
  box-shadow: var(--shadow);
  width: 95%;
  height: 90vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
.subsection {
  padding: 20px;
  box-shadow: var(--shadow);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.section-title {
  color: var(--secondary);
  font-weight: 600;
  font-size: 24px;
  margin: 0;
}
.input-name {
  color: var(--secondary);
  font-weight: 600;
  font-size: 16px;
  margin: 0 0 5px 0;
}
.input-section {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.input-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.input-lang {
  font-weight: 500;
  margin: 0 5px 0 0;
}
.dropdown-icon {
  color: var(--secondary);
  transition: var(--animation-short);
}
.dropdown-icon-selected {
  transform: rotate(180deg);
}
.deleteIcon {
  color: var(--errors-nonHover);
  font-size: 12px;
  transition: var(--animation-short);
}
.deleteIcon:hover {
  transform: scale(1.5);
  cursor: pointer;
  color: var(--errors);
}

/* Login */
.login-container {
  background-color: var(--background);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  width: 100vw;
}
.login-logo {
  width: 160px;
  height: 160px;
  box-shadow: var(--shadow);
  border-radius: var(--div-border-radius);
  margin-bottom: 50px;
}
.login-form-container {
  background-color: var(--container-background);
  border-radius: var(--div-border-radius);
  box-shadow: var(--shadow);
  justify-content: center;
  width: 35%;
  padding: 10px 30px;
  margin-bottom: 50px;
  min-width: 250px;
  display: flex;
  flex-direction: column;
}
.sign-in {
  text-align: center;
  color: var(--secondary);
  margin: 0;
  font-size: 25px;
}
.login-input-name {
  color: var(--secondary);
  font-weight: var(--bold);
  font-size: 14px;
  margin: 0;
}
.input {
  width: 100%;
  border: 0;
  border-bottom: solid 1px var(--separators-color);
  height: 25px;
  margin-bottom: 15px;
  border-radius: 0;
  padding: 0;
  color: var(--primary-font-color);
  background-color: var(--container-background);
}
.input:focus {
  outline: none;
}
.input-error {
  color: var(--errors);
}
.sign-in-btn {
  width: 190px;
  height: 40px;
  align-self: center;
  font-size: 20px;
  margin-top: 20px;
}
.login-forgot-password {
  align-self: center;
  margin: 0 0 30px 0;
  color: var(--secondary-font-color);
  transition: var(--animation-short);
}
.login-forgot-password:hover {
  color: var(--secondary);
  transform: scale(1.02);
  cursor: pointer;
}
.submited {
  opacity: 50%;
}
.keep-signed-in-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.keep-signed-in {
  margin: 0 0 3px 5px;
  color: var(--primary-font-color);
}
.keep-siged-in-icon {
  color: var(--secondary);
}
.sign-in-error {
  align-self: center;
  margin: -10px 0 5px 0;
  color: var(--errors);
  font-weight: 600;
  font-size: 12px;
}

/* NavBar */
.nav-container {
  width: 18vw;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: var(--shadow);
  overflow-y: scroll;
  height: calc(100vh - 40px);
  background-color: var(--container-background);
}
.nav-profile-container {
  margin: 0;
  min-width: 80%;
}
.nav-user-rol {
  color: var(--secondary);
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}
.nav-name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--primary-font-color);
  cursor: pointer;
}
.nav-user-name {
  margin: 0;
}
.nav-logo {
  width: 150px;
  height: auto;
  box-shadow: var(--shadow);
  border-radius: var(--div-border-radius);
  max-width: 100%;
  margin: 40px 0;
}
.nav-items {
  margin: 0;
  width: 80%;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.nav-item {
  text-decoration: none;
  list-style-type: none;
  padding: 7px 10px;
  border-radius: var(--nav-item-border-radius);
  box-shadow: 0;
  transition: var(--animation-short);
  color: var(--primary-font-color);
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  line-height: 16px;
  min-width: min-content;
}
.nav-item:hover {
  box-shadow: var(--shadow);
}
.nav-item-icon-container {
  color: var(--secondary);
}
.nav-item-icon {
  min-width: 20px;
  max-width: 20px;
  margin-right: 10px;
}
.nav-item-selected {
  background-image: var(--gradient);
  font-weight: 600;
  color: white;
  box-shadow: var(--shadow);
}
.nav-item-icon-selected {
  color: white;
}
.toggles-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  font-size: 16px;
  font-weight: 600;
}
.toggle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.toggle-name {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-font-color);
  margin: 0;
}
.toggle {
  display: flex;
  flex-direction: row;
  background-image: var(--gradient);
  border-radius: 100px;
  padding: 2px;
  align-items: center;
  box-shadow: var(--shadow);
  font-weight: 600;
}
.toggle-item {
  color: white;
  border-radius: 100px;
  padding: 4px 8px;
  transition: var(--animation-short);
  cursor: pointer;
  text-decoration: none;
}
.toggle-item:hover {
  transform: scale(1.05);
}
.toggle-item-selected {
  color: var(--secondary-font-color);
  background-color: white;
  box-shadow: var(--shadow);
}
.toggle-item-selected:hover {
  transform: scale(1);
}
@media only screen and (max-width: 1300px) {
  .toggles-container {
    flex-direction: column;
  }
}
.signOut-dropdown {
  position: absolute;
  color: var(--primary-font-color);
  border-radius: calc(var(--div-border-radius) / 2)
    calc(var(--div-border-radius) / 2) var(--div-border-radius)
    var(--div-border-radius);
  box-shadow: var(--shadow);
  padding: 2px 20px;
}
.signOut {
  transition: var(--animation-short);
  margin: 0;
  cursor: pointer;
}
.signOut:hover {
  color: var(--secondary);
}

/* Create ingredient */
.ingredient-general-container {
  z-index: 2;
  width: 40%;
}
.ingredient-nutrivalue-container {
  z-index: 1;
  width: 60%;
  box-shadow: none;
  overflow-x: hidden;
}
.ingredient-lang-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ingredient-lang-container:hover {
  cursor: pointer;
}
.ingredient-lang-checkbox {
  color: var(--secondary);
  margin-right: 5px;
  cursor: pointer;
  min-width: 16px;
}
.ingredient-lang {
  margin: 0 0 2px 0;
}
.create-ingredient-btn {
  padding: 5px 20px;
  width: auto;
  align-self: center;
  font-size: 20px;
  margin-top: 20px;
}
.ingredient-error {
  color: var(--errors);
  align-self: center;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}
.ingredient-state-container {
  border-radius: 100px;
  background-color: var(--subcontainer-background);
  padding: 2px 20px;
  max-width: fit-content;
  transition: var(--animation-short);
  cursor: pointer;
  margin: 0 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.ingredient-state-container:hover {
  transform: translateY(-3px);
  box-shadow: var(--shadow);
}
.ingredient-states-container {
  display: flex;
  min-width: 100%;
  flex-direction: row;
  min-height: min-content;
  max-width: 100%;
  overflow-x: scroll;
  padding: 12px 4px;
}
.ingredient-states-container::-webkit-scrollbar {
  height: 5px;
  position: absolute;
}
.ingredient-state-delete {
  color: var(--errors-nonHover);
  margin-left: 5px;
  font-size: 14px;
  transition: var(--animation-short);
}
.ingredient-state-delete:hover {
  transform: scale(1.3);
  color: var(--errors);
}
.viewIngredient-search-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.viewIngredient-search-header-create {
  padding: 2px 10px;
}
.createIngredient-back-containter {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding: 0 10px;
}
.createIngredient-back-arrow {
  font-size: 25px;
  margin: 2px 0 0 -10px;
}
.ingredient-searchResults-container {
  display: flex;
  flex: 1;
  overflow-y: scroll;
  flex-direction: column;
}
.ingredient-searchResults-msg {
  align-self: center;
  margin: auto;
  text-align: center;
}
.ingredient-search-result {
  border-radius: 100px;
  transition: var(--animation-short);
  padding: 2px 20px;
  width: calc(100% - 60px);
  margin: 0 10px;
  cursor: pointer;
}
.ingredient-search-result:hover {
  box-shadow: var(--shadow);
  color: var(--secondary);
}
.ingredient-search-result-selected {
  border-radius: 100px;
  padding: 2px 20px;
  width: calc(100% - 60px);
  margin: 0 10px;
  box-shadow: var(--shadow);
  background-image: var(--gradient);
  color: white;
  font-weight: 500;
}
.ingredient-view-name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 3px 20px;
}
.ingredient-view-name-lang {
  margin: 0 5px 0 0;
  color: var(--secondary);
  font-weight: 500;
}
.ingredient-view-aditionalInfo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}
.ingredient-view-aditionalInfo-bullet {
  font-weight: 700;
  color: var(--secondary);
  margin: 0 10px 0 0;
}
.ingredient-view-state-names-container {
  display: flex;
}
.ingredient-delete {
  color: var(--secondary);
  margin: 10px 0;
  align-self: center;
  transition: var(--animation-short);
  cursor: pointer;
}
.viewIngredient-editBtn {
  align-self: center;
  padding: 3px 30px;
  margin-top: 20px;
}
.ingredient-delete:hover {
  transform: scale(1.1);
}
.create-ingredient-loading {
  align-self: center;
  margin: 20px 0;
}
.ingredient-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
}
.ingredient-info-container:hover {
  cursor: pointer;
}
.ingredient-categories-loading {
  margin: 0;
  align-self: center;
  opacity: 0.5;
}

/* Create recipe */
.createRecipePopup {
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  padding: 0 20px;
  height: 100vh;
  width: calc(82vw * 0.95);
  z-index: 100;
}
.createRecipe-general-container {
  width: 25%;
  z-index: 2;
}
.createRecipe-prep-container {
  width: 40%;
  z-index: 1;
}
.createRecipe-opc-container {
  min-width: 35%;
  max-width: 35%;
  z-index: 0;
}
.createRecipe-selected-image {
  width: 50px;
  height: auto;
}
.createCat-btn {
  width: fit-content;
  padding: 3px 30px;
  align-self: center;
  margin-top: 10px;
}
.createCat {
  margin: 0;
}
.createCat-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--secondary);
}
.create-category-btn {
  width: 60%;
  padding: 2px;
  justify-self: center;
}
.createCat-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.createCategory-popup-container {
  background-color: var(--container-background);
  width: 60%;
  height: 70%;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow);
  border-radius: var(--div-border-radius);
}
.createCategory-popup-closeBtn {
  height: 30px;
  width: 30px;
  font-size: 22px;
  position: relative;
  align-self: flex-start;
  left: 20px;
  top: 20px;
  margin-bottom: 20px;
}
.createCategory-popup-title {
  color: var(--secondary);
  align-self: center;
  font-size: 30px;
  margin: 0 0 20px;
}
.createCategory-popup-content-container {
  display: flex;
  flex-direction: column;
  padding: 0 40px;
}
.createCategory-popup-btn {
  margin-top: 10px;
  width: fit-content;
  align-self: center;
  padding: 5px 30px;
  font-size: 16px;
  font-weight: 600;
}
.createRecipe-image {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 70%;
  min-height: 100px;
  height: auto;
  max-width: 100px;
  margin-top: 10px;
  box-shadow: var(--shadow);
  border-radius: var(--div-border-radius);
}
.recipe-img-input {
  justify-self: flex-end;
  padding: 2px 30px;
}
.createRecipe-img-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.createRecepy-quantity-input-container {
  display: flex;
  width: 50%;
  align-self: center;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-size: 20px;
}
.createRecepy-add-btn {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
.createRecipe-timeType {
  margin: 15px 0 5px 0;
  align-self: center;
}
.createRecipe-totalTime {
  font-weight: 600;
}
.createRecipe-ingredient-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 5px 0;
}
.createRecipe-ingredient-bullet {
  margin: -10px 10px 0 0;
  font-size: 30px;
  color: var(--secondary);
}
.createRecipe-ingredient-inputs-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.createRecipe-ingredient-input-container {
  width: 100%;
}
.hide-overflow-y {
  overflow-y: hidden;
  position: relative;
  margin-top: -15px;
  box-shadow: var(--shadow);
  border-radius: 0 0 var(--div-border-radius) var(--div-border-radius);
}
.suggestions-container {
  background-color: var(--container-background);
  padding: 10px;
  max-height: 150px;
  overflow-y: scroll;
}
.suggestion-container {
  margin: 10px 0;
}
.suggestion-container:hover {
  color: var(--secondary);
  cursor: pointer;
}
.fraction-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}
.dividedBy {
  margin: 5px 0;
  width: 50px;
  background-color: var(--secondary);
  border-radius: var(--div-border-radius);
  height: 2px;
}
.createRecepy-add-btn-mini {
  width: 20px;
  height: 20px;
  font-size: 15px;
}
.createRecepy-quantity-input-container-mini {
  width: 30%;
}
.createRecipe-cuantity-input {
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  color: var(--primary-font-color);
  text-align: center;
  max-width: 40px;
}
.createRecipe-cuantity-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.add-ingredient-btn {
  padding: 2px 20px;
  min-width: 35%;
  align-self: center;
}
.createReicpe-unit-langs-container {
  width: 100%;
  margin: 5px 0 0 20px;
}
.createRecipe-instructions-num {
  margin: 0 10px 0 0;
}
.createRecipe-tags-container {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}
.tagContainer {
  min-width: 30%;
  width: min-content;
  margin: 8px 0;
  padding: 2px 5px;
  text-align: center;
}
.unselected-tag {
  background-color: var(--container-background);
  color: var(--primary-font-color);
  font-weight: 400;
  background-image: none;
}
.unselected-tag:hover {
  background-color: var(--secondary);
}
.create-tag-btn {
  max-width: 40%;
  padding: 2px;
}
.createRecipe-btn {
  margin: 0 0 -10px 0;
  width: 50%;
  padding: 6px 10px;
  align-self: center;
}
.createRecipe-error {
  color: var(--errors);
  margin: -20px 0 0 0;
  align-self: center;
  font-size: 14px;
  font-weight: 500;
}
.accompaniment-selected {
  display: flex;
  justify-content: space-between;
  width: min-content;
  padding: 1px 15px;
  margin: 5px 0;
  text-align: center;
  overflow: hidden;
}
.accompaniment-selected:hover {
  transform: translateY(0);
}
.remove-accompaniment {
  transition: var(--animation-short);
  min-width: 16px;
}
.remove-accompaniment:hover {
  transform: scale(1.2);
}
.selectedAccompaniments {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100%;
}
.accompaniments-suggestions-container {
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px 0;
  width: 400px;
  max-width: 100%;
  align-self: center;
}
.accompaniments-suggestion-container {
  width: 40%;
  height: min-content;
  box-shadow: var(--shadow);
  border-radius: var(--div-border-radius);
  margin: 10px 0;
}
.accompaniments-suggestion-img {
  width: 100%;
  height: auto;
}
.accompaniments-suggestion-img-super-container {
  width: 100%;
  overflow: hidden;
  height: 65%;
  display: flex;
  align-items: center;
  border-top-right-radius: var(--div-border-radius);
  border-top-left-radius: var(--div-border-radius);
}
.accompaniments-suggestion-img-container {
  display: flex;
  width: calc(80vw * 0.35 * 0.4);
  overflow: hidden;
}
.accompaniments-suggestion-text-container {
  padding: 2px 10px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.accompaniments-suggestion-select {
  padding: 1px 20px;
  border-radius: var(--div-border-radius);
  box-shadow: var(--shadow);
  margin: 10px 0;
}
.accompaniments-suggestion-select:hover {
  background-image: var(--gradient);
  font-weight: 600;
  cursor: pointer;
  color: rgb(255, 255, 255);
}

/* View recipe */
.recipe-finder-container {
  width: calc(82vw * 0.95 * 0.45);
}
.recipe-finder-container::-webkit-scrollbar {
  width: 0;
}
.recipe-recipeSection-container {
  width: calc(82vw * 0.95 * 0.55);
  box-shadow: none;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
.recipe-search-container {
  display: flex;
  flex-direction: row;
  margin-bottom: -5px;
  align-items: baseline;
}
.recipe-search-btn {
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
  font-size: 20px;
}
.recipe-search-visibility-toggle {
  height: 30px;
  max-width: min-content;
  justify-content: space-between;
}
.recipe-search-results-container-empty {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.recipe-search-recipe-img {
  max-width: 100%;
}
.recipe-search-recipe-img-container {
  border-radius: 20px 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 150px;
  overflow-y: hidden;
  overflow-x: hidden;
  width: calc((82vw * 0.95 * 0.45 - 40px) * 0.4 + 1);
}
.recipe-search-recipe-container {
  width: 40%;
  box-shadow: var(--shadow);
  border-radius: 20px;
  margin: 10px 0;
}
.recipe-search-results-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 100%;
  justify-content: space-evenly;
}
.recipe-search-recipe-txt-container {
  padding: 5px 15px;
  overflow-x: hidden;
}
.recipe-search-recipe-name {
  margin: 0;
  font-weight: 500;
  text-align: center;
}
.recipe-search-recipe-category {
  margin: 0;
  font-size: 13px;
  color: var(--secondary);
  text-align: center;
}
.recipe-search-recipe-view-container {
  display: flex;
  justify-content: center;
}
.recipe-search-recipe-view {
  padding: 2px 20px;
  cursor: pointer;
  border-radius: 100px;
  box-shadow: var(--shadow);
  margin: 5px 0;
}
.recipe-recipeSection-content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  height: calc(90vh - 92px);
  width: calc(82vw * 0.95 * 0.55 - 80px);
  max-width: 100%;
  overflow-x: hidden;
}
.recipe-recipeSection-content-container-empty {
  height: 90%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.recipe-recipeSection-options-container {
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 40%;
}
.recipe-recipeSection-options-editRecipeBtn {
  width: fit-content;
  padding: 4px 35px;
  font-size: 20px;
}
.recipe-recipeSection-options-deleteRecipeBtn {
  color: var(--secondary);
  transition: var(--animation-short);
  cursor: pointer;
}
.recipe-recipeSection-options-deleteRecipeBtn:hover {
  transform: scale(1.1);
}
.recipe-recipeSection-recipe-container {
  border-radius: var(--div-border-radius);
  box-shadow: var(--shadow);
  overflow-x: visible;
  width: calc(((82vw * 0.95 * 0.55) - 80px) * 0.45);
  max-width: 45%;
  min-width: 45%;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
}
.recipe-recipeSection-recipe-container::-webkit-scrollbar {
  width: 0;
}
.recipe-recipeSection-recipe-img-container {
  width: calc((82vw * 0.95 * 0.55 - 80px) * 0.45);
  border-radius: var(--div-border-radius) var(--div-border-radius) 0 0;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  overflow-y: hidden;
  z-index: 0;
}
.recipe-recipeSection-recipe-img {
  width: 100%;
  height: auto;
  border-radius: var(--div-border-radius) var(--div-border-radius) 0 0;
}
.recipe-recipeSection-recipe-content-container {
  border-radius: var(--div-border-radius);
  margin-top: 200px;
  position: relative;
  top: -20px;
  margin-bottom: -20px;
  width: 100%;
  height: fit-content;
  z-index: 1;
  background-color: var(--container-background);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
}
.recipe-recipeSection-recipe-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}
.recipe-recipeSection-recipe-category {
  margin: 0;
  font-weight: 500;
  color: var(--secondary);
}
.recipe-recipeSection-recipe-name {
  font-size: 26px;
  font-weight: 500;
  margin: 0;
  text-align: center;
}
.recipe-recipeSection-recipe-description {
  margin: 5px 0 0 0;
  text-align: center;
  width: 90%;
  font-size: 14px;
}
.recipe-recipeSection-recipe-separator {
  width: 90%;
  height: 0.8px;
  background-color: var(--separators-color);
  margin: 10px 0;
}
.recipe-recipeSection-recipe-ingredient-container {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 2px 0 2px 10px;
}
.recipe-recipeSection-recipe-ingredient-bullet {
  font-weight: 700;
  color: var(--secondary);
  margin: 0;
  align-self: flex-start;
}
.recipe-recipeSection-recipe-ingredient-cuantity {
  margin: 0 5px 0 10px;
  font-weight: 600;
}
.recipe-recipeSection-recipe-instruction {
  margin: 0 0 0 10px;
}
.recipe-recipeSection-recipe-accompaniment-container {
  width: 100px;
  min-height: 120px;
  border-radius: var(--div-border-radius);
  box-shadow: var(--shadow);
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 5px 5px 5px 5px;
}
.recipe-recipeSection-recipe-accompaniment-img {
  height: 70px;
  width: 100%;
  border-radius: var(--div-border-radius) var(--div-border-radius) 0 0;
}
.recipe-recipeSection-recipe-accompaniment-name {
  margin: 0 10px;
  text-align: center;
  overflow-y: hidden;
}
.recipe-recipeSection-recipe-accompaniments-container {
  overflow-x: scroll;
  padding: 15px 10px;
  margin: -10px 0 -20px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.recipe-recipeSection-recipe-nutrivalues-container {
  width: 90%;
}
.recipe-recipeSection-recipe-nutrivalues-row-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border-bottom: 0.1px dotted var(--separators-color);
  padding: 2px 0;
}
.calories {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}
.nutrifact {
  font-size: 14px;
  font-weight: 600;
  margin: 0 5px 0 0;
}
.subnutrifact {
  margin: 0 5px 0 20px;
  font-size: 14px;
}
.dailyvalue {
  text-align: center;
  font-size: 12px;
  margin: 5px 0 0 0;
}
.recipe-recipeSection-recipe-notes {
  text-align: center;
  margin: 0 0 20px 0;
  width: 90%;
}
.recipe-loadMore {
  padding: 2px 20px;
  margin: 10px;
}
.err-txt {
  color: var(--errors);
}

/* Profiles */
.profiles-content-container {
  display: flex;
  flex-direction: column;
  width: calc((82vw) * 0.95 - 60px);
  height: calc(90vh - 40px);
  padding: 20px 30px;
}
.profiles-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.profiles-header {
  margin: 0;
  color: var(--secondary);
  font-size: 30px;
  font-weight: 600;
}
.profiles-addProfile-btn {
  font-size: 18px;
  padding: 3px 30px;
}
.profiles-headers-container {
  display: flex;
  flex-direction: row;
  width: calc(82vw * 0.95 - 200px);
  justify-content: space-evenly;
}
.profiles-row-container {
  display: flex;
  flex-direction: row;
  width: calc(82vw * 0.95 - 170px);
  justify-content: space-evenly;
  position: relative;
  left: -10px;
}
.profiles-headers {
  margin: 0;
  color: var(--secondary);
  font-size: 15px;
  width: 200px;
  text-align: center;
  font-weight: 600;
}
.profiles-row-text {
  width: 200px;
  margin: 15px 0;
  text-align: center;
}
.profiles-row-text-name {
  padding: 0 10px;
  width: 180px;
}
.profiles-row-index {
  color: var(--secondary);
  width: 10px;
  align-self: baseline;
  margin: 15px 0;
}
.profiles-editBtn {
  width: 130px;
  padding: 3px 0;
  height: min-content;
}
.profiles-superRow-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--separators-color);
}
.profiles-propup-background {
  position: absolute;
  width: calc(82vw * 0.95);
  height: 90vh;
  border-radius: var(--div-border-radius);
  background-color: var(--popUp-background);
  right: calc(81vw * 0.025);
  bottom: calc(10vh * 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: var(--blurColor);
  -webkit-backdrop-filter: var(--blur);
  backdrop-filter: var(--blur);
}
.profiles-propup-container {
  width: calc(50% - 60px);
  height: calc(70% - 60px);
  background-color: var(--container-background);
  border-radius: var(--div-border-radius);
  box-shadow: var(--shadow);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  z-index: 2;
  overflow-y: scroll;
}
.profiles-popup-closeBtn {
  position: absolute;
  right: calc(25% + 15px);
  top: calc(15% + 15px);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  z-index: 2;
}
.profiles-popup-title {
  color: var(--secondary);
  margin: 0;
  font-weight: 600;
}
.profiles-popup-input {
  text-align: center;
  width: 90%;
  margin: 0;
}
.profiles-popup-input-name {
  margin: 20px 0 5px 0;
  color: var(--secondary);
  font-weight: 500;
}
.profiles-popup-email {
  margin: 0;
}
.profiles-showPassword {
  margin: 2px 0 0 0;
  color: var(--secondary);
  cursor: pointer;
  font-size: 12px;
}
.profiles-toggle-item {
  white-space: nowrap;
}
.profiles-popup-day-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.profiles-popup-ranges-instruction {
  color: var(--secondary);
  font-size: 13px;
  margin: 0;
}
.profiles-popup-day-checkbox {
  color: var(--secondary);
  margin-right: 10px;
}
.profiles-popup-day {
  margin: 0;
}
.profiles-popup-ranges-supercontainer {
  display: flex;
  flex-direction: row;
  margin: 0 0 20px 0;
}
.profiles-popup-range-index {
  margin: 0;
}
.profiles-popup-range-bullets-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
}
.profiles-popup-hours-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 5px;
}
.hour-input {
  border: 1px solid var(--separators-color);
  border-radius: 5px;
  width: 50px;
  margin: 0;
  text-align: center;
}
.profiles-popup-range-addBtn {
  margin: 0;
  padding: 2px 30px;
}
.profiles-popup-submit {
  font-size: 20px;
  padding: 5px 40px;
}
.profiles-empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
}
.profiles-popup-loading {
  opacity: 0.5;
  margin: 15px 0 0 0;
}
.profiles-handleProfile-secondaryBtn-container {
  width: 20px;
  height: 20px;
  margin: 0 25px;
}
.profiles-handleProfile-secondaryBtn {
  color: var(--secondary);
  cursor: pointer;
  transition: var(--animation-short);
  font-size: 15px;
}
.profiles-handleProfile-secondaryBtn:hover {
  transform: scale(1.2);
}
.profiles-stats-noUsage {
  opacity: 0.5;
  margin: 0;
}
.profile-referralCodeUsage-row-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.profile-referralCodeUsage-index {
  color: var(--secondary);
  margin: 0 10px 0 20px;
}
.profile-referralCodeUsage-data-container {
  display: flex;
  flex-direction: row;
}
.profile-referralCodeUsage-field {
  color: var(--secondary);
  margin: 0 10px 0 0;
}
.profile-referralCodeUsage-data {
  margin: 0;
}

/* Stats */
.stats-container {
  width: 95%;
  height: 90vh;
}
.stats-header {
  width: calc(100% - 0px);
  background-color: var(--container-background);
  box-shadow: var(--shadow);
  border-radius: var(--div-border-radius);
  padding: 35px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.stats-header-item-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.stats-header-separator {
  width: 2px;
  height: 85px;
  background-color: var(--separators-color);
}
.stats-header-item-name {
  color: var(--secondary);
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  text-align: center;
}
.stats-header-item {
  margin: -10px;
  font-size: 60px;
  font-weight: 400;
}
.stats-content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.stats-content-subcontainer {
  width: 48%;
}
.stats-content-section-container {
  background-color: var(--container-background);
  box-shadow: var(--shadow);
  border-radius: var(--div-border-radius);
  padding: 10px 20px;
  margin: 30px 0;
}
.stats-section-title {
  font-weight: 500;
  color: var(--secondary);
  margin: 0 0 10px 0;
}
.stats-newUsers-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.stats-newUsers-graph-container {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.stats-row-index {
  margin: 0 10px 0 0;
  font-weight: 700;
  color: var(--secondary);
}
.stats-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.stats-row-leftSection-container {
  display: flex;
  flex-direction: row;
}
.stats-row-text {
  margin: 0;
}
.stats-row-separator {
  width: 100%;
  height: 1px;
  background-color: var(--separators-color);
  margin: 15px 0;
}
.stats-loading {
  opacity: 0.5;
  margin: 15px 0;
}

/* Agenda */
.agenda-monthsSection-container {
  width: 20%;
  z-index: 2;
}
.agenda-daysSection-container {
  width: 50%;
  z-index: 1;
  padding-bottom: 0;
}
.agenda-appointmentSection-container {
  min-width: 30%;
  max-width: 30%;
  z-index: 0;
  overflow-x: hidden;
}
.agenda-monthsSection-months-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.agenda-monthsSection-month-container {
  width: 100%;
  border-radius: var(--div-border-radius);
  margin: 10px 0;
  padding: 5px;
  transition: var(--animation-short);
}
.agenda-monthsSection-month-container:hover {
  box-shadow: var(--shadow);
}
.agenda-monthsSection-weekDays-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.agenda-monthsSection-day {
  margin: 0;
  width: 14%;
  text-align: center;
  font-size: 12px;
  border-radius: 100px;
  transition: var(--animation-short);
}
.agenda-monthsSection-day:hover {
  cursor: pointer;
  font-weight: 500;
  transform: scale(1.3);
}
.agenda-monthsSection-weekday {
  font-weight: 500;
}
.agenda-monthsSection-weekday:hover {
  transform: scale(1);
  cursor: default;
}
.agenda-monthsSection-dates-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.agenda-monthsSection-month-name {
  color: var(--secondary);
  margin: 3px 0 0 0;
}
.agenda-monthsSection-month-container-selected {
  background-image: var(--gradient);
  color: white;
  box-shadow: var(--shadow);
}
.agenda-monthsSection-month-name-selected {
  color: white;
}
.agenda-monthsSection-day-selected {
  background-color: white;
  color: var(--primary);
  transform: scale(1);
}
.agenda-monthsSection-day-selected:hover {
  transform: scale(1);
  font-weight: 400;
  cursor: default;
}
.agenda-monthsSection-day-today {
  font-weight: 500;
  transform: scale(1);
  background-image: var(--gradient);
  color: white;
}
.agenda-daysSection-header-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 8px;
}
.agenda-daysSection-header-week-container {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.agenda-daysSection-header-items-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex: 1;
}
.agenda-daysSection-header-item-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: var(--animation-short);
  cursor: pointer;
}
.agenda-daysSection-header-item-container:hover {
  transform: scale(1.1);
}
.agenda-daysSection-header-item-day {
  margin: 0;
  font-size: 10px;
}
.agenda-daysSection-header-item-date {
  margin: 0;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
}
.agenda-daysSection-header-item-date-selected {
  background-image: var(--gradient);
  color: white;
  font-weight: 500;
}
.agenda-daysSection-header-fullDate {
  margin: 0;
  font-size: 12px;
}
.agenda-daysSection-header-arrow {
  font-size: 30px;
  color: var(--secondary);
  transition: var(--animation-short);
  cursor: pointer;
}
.agenda-daysSection-header-arrow:hover {
  transform: scale(1.2);
}
@media only screen and (max-width: 1400px) {
  .agenda-monthsSection-day {
    font-size: 10px;
  }
}
.agenda-daysSection-loading-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.agenda-daysSection-loading {
  max-width: 70%;
}
.agenda-daysSection-dropdown {
  color: white;
  background-image: var(--gradient);
  border: 0;
  border-radius: 20px;
  box-shadow: var(--shadow);
  padding: 5px 20px;
}
.agenda-daysSection-dropdown::-ms-expand {
  display: none;
}
.agenda-daysSection-dropdown-option {
  background-color: var(--container-background);
  color: var(--primary-font-color);
  border: 0;
}
.agenda-daysSection-daysContainner {
  flex: 1;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  overflow-x: hidden;
}
.agenda-daysSection-hour-superContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.agenda-daysSection-hour-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.agenda-daysSection-hour {
  font-size: 12px;
  font-weight: 500;
  margin: 0 10px 0 0;
}
.agenda-daysSection-hour-separator {
  flex: 1;
  background-color: var(--separators-color);
  height: 1px;
}
.agenda-daysSection-appointment-superContainer {
  height: 80px;
  margin: -6px 0;
}
.agenda-daysSection-appointment-container {
  position: relative;
  border-radius: var(--div-border-radius);
  margin: 2px 10px 2px 40px;
  max-height: calc(50% - 14px);
  display: flex;
  flex-direction: row;
  padding: 5px 20px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: var(--animation-short);
}
.agenda-daysSection-appointment-container:hover {
  box-shadow: var(--shadow);
}
.agenda-daysSection-appointment-name {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}
.agenda-daysSection-appointment-id {
  margin: 0;
  font-size: 12px;
  color: var(--secondary);
}
.agenda-daysSection-appointment-id-selected {
  color: white;
}
.agenda-daysSection-appointment-container-selected {
  box-shadow: var(--shadow);
  color: white;
  background-image: var(--gradient);
}
.agenda-daysSection-appointment-arrow {
  font-size: 20px;
}
.agenda-appointmentSection-empty-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.agenda-appointmentSection-header-img-container {
  width: 170px;
  height: 170px;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  border-radius: 1000px;
  box-shadow: var(--shadow);
  margin: 10px 0;
}
.agenda-appointmentSection-header-img {
  width: 100%;
  height: auto;
}
.agenda-appointmentSection-content-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.agenda-appointmentSection-header-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid var(--separators-color);
  min-width: 100%;
  padding-bottom: 15px;
}
.agenda-appointmentSection-header-title {
  color: var(--secondary);
  font-size: 20px;
  margin: 0;
}
.agenda-appointmentSection-header-name {
  margin: 0;
  font-weight: 500;
  font-size: 27px;
}
.agenda-appointmentSection-header-id {
  color: var(--secondary);
  margin: 0;
  font-size: 14px;
}
.agenda-appintmentSection-fieldName {
  margin: 10px 0 0 0;
  color: var(--secondary);
  font-weight: 500;
}
.agenda-appintmentSection-field-container {
  width: 100%;
}
.agenda-appointmentSection-field {
  margin: 0;
  font-size: 15px;
}
.agenda-appointmentSection-field-horizontal-container {
  display: flex;
  flex-direction: row;
}
.agenda-appointmentSection-field-subName {
  font-weight: 500;
  margin-right: 5px;
}

/* EditUser */
.editUSer-userFinder-container {
  width: 25%;
  z-index: 2;
}
.editUser-userPlan-container {
  width: 30%;
  z-index: 1;
}
.editUSer-thirdSection-container {
  width: 45%;
  z-index: 0;
}
.editUser-userFinder-empty-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.editUser-userFinder-seach-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.editUser-userFinder-seach {
  padding: 5px;
  margin: 0 0 0 10px;
  max-width: 20px;
  min-width: 20px;
  max-height: 20px;
  min-height: 20px;
  margin-bottom: 15px;
}
.editUser-userFinder-user-container {
  width: calc(100%-40px);
  border-radius: var(--div-border-radius);
  padding: 5px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: var(--animation-short);
  cursor: pointer;
}
.editUser-userFinder-user-container:hover {
  box-shadow: var(--shadow);
}
.editUser-userFinder-user-name {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}
.editUser-userFinder-user-id {
  margin: 0;
  font-size: 12px;
  color: var(--secondary);
}
.editUser-userFinder-user-id-selected {
  color: white;
}
.editUser-userFinder-user-arrow {
  font-size: 20px;
}
.editUser-userFinder-user-container-selected {
  box-shadow: var(--shadow);
  background-image: var(--gradient);
  color: white;
}
.editUser-userFinder-dateSelector-container {
  margin: 10px 0 20px 0;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--separators-color);
}
.editUSer-userFinder-meal-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  border-radius: var(--div-border-radius);
  transition: var(--animation-short);
  padding: 5px 20px;
  margin: 10px 0;
  cursor: pointer;
}
.editUSer-userFinder-meal-container:hover {
  box-shadow: var(--shadow);
}
.editUSer-userFinder-meal-mealType {
  margin: 0;
  font-size: 12px;
  color: var(--secondary);
  font-weight: 500;
}
.editUSer-userFinder-meal-name {
  margin: 0;
}
.editUSer-userFinder-meal-switchBtn {
  margin: 0;
  font-size: 12px;
  color: var(--secondary);
  font-weight: 500;
  padding: 1px 10px;
  border-radius: var(--div-border-radius);
  text-align: center;
}
.editUSer-userFinder-meal-switchBtn:hover {
  box-shadow: var(--shadow);
  background-image: var(--gradient);
  color: white;
}
.editUser-userFinder-settingsBtn {
  padding: 5px 20px;
  width: 50%;
  align-self: center;
  margin: 20px 0 0 0;
  text-align: center;
}
.editUSer-userFinder-meal-container-selected {
  background-image: var(--gradient);
  color: white;
  box-shadow: var(--shadow);
}
.editUSer-userFinder-meal-mealType-selected {
  color: white;
}
.editUSer-userFinder-meal-arrow {
  font-size: 20px;
}
.editUSer-recipeFinder-results-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 100%;
  justify-content: space-evenly;
}
.editUSer-recipeFinder-results-container-empty {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.editUser-notes-createNote-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.editUser-notes-createNote {
  color: var(--secondary);
  font-weight: 500;
}
.editUser-notes-createNote-btn {
  padding: 2px 20px;
}
.editUser-notes-note-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}
.editUser-notes-note-author {
  font-weight: 500;
  color: var(--secondary);
  margin: 0;
}
.editUser-notes-note-date {
  color: var(--primary-font-color-lowOpacity);
  font-size: 13px;
  font-weight: 300;
  margin: 0 0 0 5px;
}
.editUser-notes-note {
  margin: 0;
}
.editUser-servings-saveBtn {
  padding: 1px 20px;
  align-self: center;
}
.editUser-exlusion-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
.editUser-exlusion-length {
  font-weight: 300;
  margin: 0 0 0 5px;
  color: var(--primary-font-color-lowOpacity);
}
.editUser-exlusion-view {
  color: var(--secondary);
  margin: 0 0 0 20px;
  cursor: pointer;
  transition: var(--animation-short);
}
.editUser-exlusion-view:hover {
  transform: scale(1.1);
}
.editUser-exclusion {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.editUser-exclusion-bullet {
  color: var(--secondary);
  margin: 0 5px 0 15px;
}
.editUSer-exclusion-createExclusion-container {
  margin: -10px 30px 0 30px;
  display: flex;
  flex-direction: column;
}
.editUser-exclusions-createBtn {
  width: fit-content;
  padding: 2px 20px;
  align-self: center;
  margin-top: 15px;
}
.editUSer-exclusions-save-btn {
  width: fit-content;
  padding: 3px 30px;
  align-self: center;
  margin-top: 15px;
}

/* Events */
.events-generalSection-conainer {
  width: 25%;
  z-index: 2;
}
.events-contentSection-conainer {
  width: 40%;
  z-index: 1;
}
.events-durationSection-container {
  width: 35%;
  z-index: 0;
}
.datePicker {
  display: none;
}
.events-dates-container {
  display: flex;
  justify-content: space-evenly;
}
.events-dateContainer {
  max-width: 50%;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.dateSelector {
  padding: 2px 15px;
}
.event-publish {
  font-size: 20px;
  padding: 4px 40px;
  width: fit-content;
  align-self: center;
  margin-top: 50px;
}
.event-error {
  color: var(--errors);
  align-self: center;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

/* @media only screen and (max-width: 900px) {
  .content-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }
  .subsection {
    min-width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    overflow-y: visible;
  }
} */
